// PaymentComponent.js
import React, { useEffect } from 'react';

const PaymentComponent = ({ amount, description }) => {
    useEffect(() => {
        if (window.Moyasar) {
            window.Moyasar.init({
                element: '.mysr-form',
                amount: amount * 100,
                currency: 'SAR',
                description: description ? description : 'To Help The Environment',
                publishable_api_key: 'pk_test_fxVheGXfwHvukVaLFS37yfQBkhE6Qu7Yn9DBcP9c',
                callback_url: `${window.location.origin}/profile/payment-callback`,
                methods: ['creditcard'],
                on_completed: (payment) => {
                    console.log('Payment completed');
                }
            });
        }
    }, [amount, description]);

    return <div className="mysr-form"></div>;
};

export default PaymentComponent;
