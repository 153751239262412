import React from "react";
import { Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriverWrench, faRotate, faUsers } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/integration.css'
const ProcessCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={4} md={6}>
        <div className="custom-card text-left rounded">
          <div className="icon-container mb-3">
            <FontAwesomeIcon icon={props.icon} size="2x" style={{ color: '#333' }} />
          </div>
          <h5 className="fs-18 card-title">{props.step}</h5>
          <p className="text-muted card-description">{props.caption}</p>
        </div>
      </Col>
    </React.Fragment>
  );
}

// Process Data

const ProcessData = [
  {
    icon: { faUsers },
    step: "Engage your users",
    caption: "Empower your users with easy access to climate action. Enable them to back certified carbon projects and technologies seamlessly.",
  },
  {
    number: "",
    step: "Streamline your carbon initiatives",
    caption: "Automate your carbon strategy and save time by integrating carbon credits into your workflow. Purchase credits instantly to offset emissions.",
  },
  {
    number: "03",
    step: "Tailor a smooth experience",
    caption: "Customize c-mass's simple purchasing flow with your own branding and UI. Simplify the process so users don't have to worry about multiple choices.",
  }
];


const Integration = () => {
  return (
    <React.Fragment>
      <section className="bg-process section" id="process" style={{backgroundColor: '#8ae5c3'}}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <h3>Seamless integration
                </h3>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-2">
            {/* Process Component Start */}

            {/*ProcessData.map((value, idx) => (
              <ProcessCard
                key={idx}
                number={value.number}
                step={value.step}
                caption={value.caption}
                isShow={value.isShow}
              />
            ))*/}
            <Col lg={4} md={6}>
              <div className="custom-card text-left rounded">
                <div className="icon-container mb-3">
                  <FontAwesomeIcon icon={faUsers} size="2x" style={{ color: '#333' }} />
                </div>
                <h5 className="fs-18 card-title">Engage your users</h5>
                <p className="text-muted card-description">Empower your users with easy access to climate action. Enable them to back certified carbon projects and technologies seamlessly.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="custom-card text-left rounded">
                <div className="icon-container mb-3">
                <FontAwesomeIcon icon={faRotate} size="2x" style={{ color: '#333' }}/>
                </div>
                <h5 className="fs-18 card-title">Streamline your carbon initiatives</h5>
                <p className="text-muted card-description">Automate your carbon strategy and save time by integrating carbon credits into your workflow. Purchase credits instantly to offset emissions.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="custom-card text-left rounded">
                <div className="icon-container mb-3">
                <FontAwesomeIcon icon={faScrewdriverWrench} size="2x" style={{ color: '#333' }}/>
                </div>
                <h5 className="fs-18 card-title">Tailor a smooth experience</h5>
                <p className="text-muted card-description">Customize c-mass's simple purchasing flow with your own branding and UI. Simplify the process so users don't have to worry about multiple choices.</p>
              </div>
            </Col>
            {/* Process Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Integration;
