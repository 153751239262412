// CheckoutPage.js
import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import PaymentComponent from '../../components/PaymentComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../../contexts/mode/ModeContext';
import OrderContext from '../../contexts/order/OrderContext';

const CheckoutPage = () => {
    const location = useLocation();
    const { isSandboxMode } = useContext(ModeContext);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const { orderData } = useContext(OrderContext); // Access orderData and clearOrderData
    console.log(orderData);
    if (!orderData) {
        return <p>Error: Order data is missing. Please go back and try again.</p>;
    }

    const handlePlaceOrder = () => {
        sessionStorage.setItem('orderData', JSON.stringify(orderData)); // Store order data in session storage
    };

    return (
        <Container fluid style={{ minHeight: '100vh' }}>
            <Row className="justify-content-center">
                <Col md="6" lg="5">
                    <h2 className="mt-4">Confirm your order</h2>
                    <Card style={{ borderRadius: '15px', marginTop: '20px' }}>
                        <CardBody>
                            <h4 className="mt-4">Order summary</h4>
                            <div className="d-flex justify-content-between">
                                <span>Portfolio</span>
                                <span>{orderData.portfolio.name}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Carbon credits</span>
                                <span>{orderData.kg_amount} kg</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Description</span>
                                <span>{orderData.description}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <strong>Order total</strong>
                                <strong>SAR {(orderData.totalPrice).toFixed(2)}</strong>
                            </div>
                            {/*<Button color="primary" block className="mt-4" onClick={handlePlaceOrder} style={{ borderRadius: '25px' }}>
                                Place order
                            </Button>*/}
                            {error && <Alert color="danger">{error}</Alert>}
                            {success && <Alert color="success">{success}</Alert>}
                            <p className="text-center mt-3">
                                By clicking "Pay," you agree to the <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
                            </p>
                            <h4>Payment method</h4>
                            <PaymentComponent amount={orderData.totalPrice} description={orderData.description} />
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CheckoutPage;
