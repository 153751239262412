import React from 'react';
import '../assets/css/widgetPreview.css'; // Import the CSS file

const WidgetPreview = ({ customURL, equivalents }) => {
    
    // Define the equivalents array
    const equivalentsList = [
        {
            name: 'trees',
            multiplier: 0.022,
            description: 'new trees planted',
            icon: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1725880667/wyu6jwhf4xg7ggup2zmn.png'
        },
        {
            name: 'cars',
            multiplier: 0.00021,
            description: 'cars off the road for a year',
            icon: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1725880666/ubnlixaceiqlatdbxokr.png'
        },
        {
            name: 'homes',
            multiplier: 0.00022,
            description: 'homes\' annual energy usage',
            icon: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1725880666/itnaidze30iyervejugf.png'
        },
        {
            name: 'flights',
            multiplier: 0.0011,
            description: 'flights from Riyadh to London',
            icon: 'https://res.cloudinary.com/ddyt65zji/image/upload/v1725880666/cssmkcff3ufw3uly0bl8.png'
        }
    ];

    // Simulated company data for the preview (replace with actual data in a real app)
    const companyData = {
        lastOrderDetails: {
            date: '2024-09-06', // Example date
            credits: [{ amount: 6 }] // Example credits amount
        }
    };

    const selectedEquivalents = Object.keys(equivalents).filter(eq => equivalents[eq]);

    return (
        <div className="cmass-impact-embed">
            <div className="impact-container main-cont">
                <h4>We are taking climate action</h4>
                <p className="t1">Since {companyData.lastOrderDetails.date}, we have offset:</p>
                <div className="react-shadow-101wtpb"></div>
                <h2 className="offset">{companyData.lastOrderDetails.credits[0].amount},000 </h2>
                <div className="react-shadow-101wtpb"></div>
                <div className="kg">kg of CO₂e</div>
            </div>

            <div className="equivalents-section">
                <h2>That's the same climate impact as:</h2>
                <div className="equivalents-row">
                    {selectedEquivalents.map(eq => {
                        const equivalent = equivalentsList.find(item => item.name === eq);
                        if (equivalent) {
                            const calculatedValue = (companyData.lastOrderDetails.credits[0].amount * equivalent.multiplier).toFixed(1);
                            return (
                                <div key={eq} className="equivalents-item">
                                    <img
                                        src={equivalent.icon}
                                        alt={equivalent.description}
                                    />
                                    <h3>{calculatedValue}</h3>
                                    <p>{equivalent.description}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>

            <div className="powered-by">
                Powered by c-mass
            </div>
        </div>
    );
};

export default WidgetPreview;
