// PaymentSetupStatus.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaymentSetupStatus = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('Processing your payment...');
    const [paymentData, setPaymentData] = useState(null);

    // Extract query parameters
    const query = new URLSearchParams(location.search);
    const paymentId = query.get('id');
    const status = query.get('status');
    const message = query.get('message');

    useEffect(() => {
        // Function to fetch the payment details from your API using the paymentId
        const fetchPaymentData = async () => {
            try {
                const response = await axios.get(`/api/payment-info/${paymentId}`);
                setPaymentData(response.data);
            } catch (error) {
                console.error('Error fetching payment info:', error);
            }
        };

        if (paymentId) {
            fetchPaymentData();
        }

        // Display message based on payment status
        if (status === 'paid') {
            setStatusMessage('Payment Approved! Redirecting...');
        } else {
            setStatusMessage('Payment Rejected. Redirecting...');
        }

        // Redirect after 3 seconds
        setTimeout(() => {
            navigate('/profile/developer'); // Redirect to a payments or profile page
        }, 3000);
    }, [status, paymentId, navigate]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
            <div>
                <h2>{statusMessage}</h2>
                {paymentData && (
                    <div>
                        <p><strong>Payment ID:</strong> {paymentData.paymentId}</p>
                        <p><strong>Amount:</strong> {paymentData.amount} {paymentData.currency}</p>
                        <p><strong>Description:</strong> {paymentData.description}</p>
                        <p><strong>Card Company:</strong> {paymentData.cardCompany}</p>
                        <p><strong>Card Number:</strong> {paymentData.cardNumber}</p>
                    </div>
                )}
                {!paymentData && <p>Loading payment details...</p>}
            </div>
        </div>
    );
};

export default PaymentSetupStatus;