import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import UserContext from '../../../contexts/user/UserContext'; // To get the logged-in user
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Accordion, AccordionItem, AccordionHeader, AccordionBody, Container } from 'reactstrap'; // Import Table and Modal from reactstrap

const LeadDetailPage = () => {
    const { user } = useContext(UserContext); // Get logged-in user data
    const { leadId } = useParams(); // Get the leadId from the URL params
    const [lead, setLead] = useState(null); // Store the lead data
    const [loading, setLoading] = useState(true); // Handle loading state
    const [error, setError] = useState(null); // Handle error state
    const [comments, setComments] = useState([]); // Store comments
    const [newComment, setNewComment] = useState(''); // New comment input
    const [newProject, setNewProject] = useState({
        name: '',
        location: '',
        type: '',
        credits: '',
        price: '',
        vintage: '',
        registry: '',
        availabilityNow: true,
        availabilityDate: ''
    });
    const [projectModalOpen, setProjectModalOpen] = useState(false); // Control project modal visibility
    const [openAccordion, setOpenAccordion] = useState(''); // Control accordion open state for contacts

    // Fetch the lead details when the component mounts
    useEffect(() => {
        const fetchLeadDetails = async () => {
            try {
                const response = await http.get(`${API_ENDPOINTS.LEAD}/${leadId}`); // API call to fetch lead details
                setLead(response.data); // Set the fetched lead data
                setComments(response.data.comments || []); // Set comments if available
                setLoading(false); // Set loading to false
            } catch (error) {
                console.error('Error fetching lead details:', error);
                setError('Failed to fetch lead details');
                setLoading(false);
            }
        };

        fetchLeadDetails();
    }, [leadId]);

    // Handle loading and error states
    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const getAvailabilityBadgeClass = (availability) => {
        if (availability?.now) {
            return 'bg-success text-white'; // Green badge for available now
        } else {
            return 'bg-warning text-dark'; // Yellow badge for future availability
        }
    };

    // Handle adding new comment
    const handleAddComment = async () => {
        if (newComment) {
            try {
                // Assuming we are sending comment to the server to save it
                const comment = {
                    commenter: user.email, // Assuming commenter is the current user
                    date: new Date().toISOString(),
                    content: newComment
                };
                
                await http.post(`${API_ENDPOINTS.LEAD}/${leadId}/comment`, { content: newComment });
                
                setComments([...comments, comment]);
                setNewComment(''); // Clear input after adding
            } catch (error) {
                console.error('Error adding comment:', error);
            }
        }
    };

    const toggleProjectModal = () => {
        setProjectModalOpen(!projectModalOpen);
    };

    const handleProjectChange = (e) => {
        const { name, value } = e.target;
        setNewProject({ ...newProject, [name]: value });
    };

    const addProject = async () => {
        try {
            const project = {
                ...newProject,
                availability: {
                    now: newProject.availabilityNow,
                    date: newProject.availabilityNow ? null : newProject.availabilityDate,
                }
            };
            
            // Assuming we are sending project to the server to save it
            await http.post(`${API_ENDPOINTS.LEAD}/${leadId}/project`, { project });
            
            setLead({
                ...lead,
                projects: [...lead.projects, project]
            });
            setNewProject({
                name: '',
                location: '',
                type: '',
                credits: '',
                price: '',
                vintage: '',
                registry: '',
                availabilityNow: true,
                availabilityDate: ''
            });
            toggleProjectModal(); // Close modal after adding
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? '' : id); // Toggle accordion for contact info
    };

    return (
        <Container>
            {lead ? (
                <>
                    <h2>{lead.name}</h2>
                    <p><strong>Location:</strong> {lead.location}</p>

                    {/* Contact Info Accordion */}
                    <h3>Contact Information</h3>
                    <Accordion open={openAccordion} toggle={toggleAccordion}>
                        {lead.contactInfo && lead.contactInfo.map((contact, index) => (
                            <AccordionItem key={index}>
                                <AccordionHeader targetId={index.toString()}>
                                    {contact.name}
                                </AccordionHeader>
                                <AccordionBody accordionId={index.toString()}>
                                    <p><strong>Title:</strong> {contact.title}</p>
                                    <p><strong>Email:</strong> {contact.email}</p>
                                    <p><strong>Phone:</strong> {contact.phone}</p>
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>

                    <h3>Projects</h3>
                    {/* Add Project Button */}
                    <Button color="primary" onClick={toggleProjectModal} className="rotate-button" style={{ borderRadius: '30px', padding: '6px 14px' }}>
                        Add Project
                    </Button>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Type</th>
                                <th>Credits</th>
                                <th>Price</th>
                                <th>Vintage</th>
                                <th>Registry</th>
                                <th>Availability</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lead.projects && lead.projects.map((project, index) => (
                                <tr key={index}>
                                    <td>{project.name}</td>
                                    <td>{project.location}</td>
                                    <td>{project.type}</td>
                                    <td>{project.credits}</td>
                                    <td>{project.price}</td>
                                    <td>{project.vintage}</td>
                                    <td>{project.registry}</td>
                                    <td>
                                        <span className={`badge ${getAvailabilityBadgeClass(project.availability)}`}>
                                            {project.availability?.now
                                                ? 'Available' 
                                                : `${project.availability?.date.split('T')[0]}`}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>


                    {/* Add Project Modal */}
                    <Modal isOpen={projectModalOpen} toggle={toggleProjectModal}>
                        <ModalHeader toggle={toggleProjectModal}>Add New Project</ModalHeader>
                        <ModalBody>
                            <input
                                type="text"
                                placeholder="Project Name"
                                name="name"
                                value={newProject.name}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />
                            <input
                                type="text"
                                placeholder="Project Location"
                                name="location"
                                value={newProject.location}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />
                            <input
                                type="text"
                                placeholder="Project Type"
                                name="type"
                                value={newProject.type}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />
                            <input
                                type="number"
                                placeholder="Credits Quantity"
                                name="credits"
                                value={newProject.credits}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />
                            <input
                                type="text"
                                placeholder="Price (Range if applicable)"
                                name="price"
                                value={newProject.price}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />
                            <input
                                type="text"
                                placeholder="Vintage"
                                name="vintage"
                                value={newProject.vintage}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />
                            <input
                                type="text"
                                placeholder="Registry"
                                name="registry"
                                value={newProject.registry}
                                onChange={handleProjectChange}
                                className="form-control mb-2"
                            />

                            <h6>Availability</h6>
                            <div className="mb-3">
                                <label>
                                    <input
                                        type="radio"
                                        name="availabilityNow"
                                        value="now"
                                        checked={newProject.availabilityNow}
                                        onChange={() => setNewProject({ ...newProject, availabilityNow: true, availabilityDate: '' })}
                                    />
                                    Available Now
                                </label>
                                <label className="ml-3">
                                    <input
                                        type="radio"
                                        name="availabilityNow"
                                        value="future"
                                        checked={!newProject.availabilityNow}
                                        onChange={() => setNewProject({ ...newProject, availabilityNow: false })}
                                    />
                                    Available in the Future
                                </label>
                                {!newProject.availabilityNow && (
                                    <input
                                        type="date"
                                        name="availabilityDate"
                                        value={newProject.availabilityDate}
                                        onChange={(e) => setNewProject({ ...newProject, availabilityDate: e.target.value })}
                                        className="form-control mt-2"
                                    />
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={addProject}>Add Project</Button>
                            <Button color="secondary" onClick={toggleProjectModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    {/* Comments Section */}
                    <h3>Comments</h3>
                    <ul className="list-unstyled">
                        {comments.map((comment, index) => (
                            <li key={index} className="mb-2 p-2 border rounded">
                                <strong>{comment.commenter}</strong> - <em>{new Date(comment.date).toLocaleDateString()}</em>
                                <p>{comment.content}</p>
                            </li>
                        ))}
                    </ul>

                    {/* Add Comment Section */}
                    <div className="mt-3">
                        <textarea
                            className="form-control"
                            placeholder="Add a comment"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button color="primary" className="rotate-button" style={{ borderRadius: '30px', padding: '6px 14px', marginTop: '5px' }} onClick={handleAddComment}>Add Comment</Button>
                    </div>
                </>
            ) : (
                <p>No lead details available</p>
            )}
        </Container>
    );
};

export default LeadDetailPage;
