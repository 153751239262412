import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { ModeContext } from '../../contexts/mode/ModeContext';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/orderPage.css"; // Create and import your CSS file
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faWeightScale } from '@fortawesome/free-solid-svg-icons';

const OrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isSandboxMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.ORDER_DETAILS}/${orderId}?isSandbox=${isSandboxMode}`);
        console.log(response.data.order)
        setOrder(response.data.order);
        setLoading(false);
        console.log(response.data.order.projectRecords)
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId, isSandboxMode]);

  return (
    <div className="order-page">
      {loading ? <Container><Spinner /></Container> : (
        <Container>
          <Row className="order-header mt-4">
            <Col md="6">
              <h4 className="order-id">Order ID: <span className="order-id-value">{order.orderNumber}</span></h4>
              <h1 className="order-title">Order Details</h1>
              <ul className="order-details-list">
                <li><strong>Placed On:</strong> {new Date(order.createdAt).toLocaleString()}</li>
                <li><strong>Amount:</strong> {order.creditsPurchased} kg</li>
                <li><strong>Portfolio:</strong> {order.portfolio?.name}</li>
                <li><strong>Purchased By:</strong> {order.company?.name}</li>
              </ul>
            </Col>
          </Row>

          <Row className="fulfilled-section mt-5">
            <Col>
              <h2 className="fulfilled-title">Fulfilled With</h2>
              <div className="fulfilled-projects-container">
                {order.projectRecords?.map((projectRecord, index) => (
                  <div key={index} className="fulfilled-project">
                    <h4 className="project-name">{projectRecord.projectId?.name}</h4>
                    <p className="project-type">{projectRecord.projectCategoryId?.name}</p>
                    <ul className="project-details">
                      <li><FontAwesomeIcon icon={faWeightScale} /> {(projectRecord.delta).toFixed(2)} kg</li>
                      <li><i className="bi bi-building"></i> {projectRecord?.projectId?.location}</li>
                      <li><i className="bi bi-check-circle"></i> {projectRecord?.projectId?.carbonRegistry?.name} ID: <a href='https://registry.verra.org/app/projectDetail/VCS/2250'>{projectRecord?.projectId?.carbonRegistry.id}</a></li>
                    </ul>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default OrderPage;
