// OrderContext.js
import React, { createContext, useState, useEffect } from 'react';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const [orderData, setOrderData] = useState(() => {
        // Load from sessionStorage on initial load
        const savedData = sessionStorage.getItem('orderData');
        return savedData ? JSON.parse(savedData) : null;
    });

    const saveOrderData = (data) => {
        setOrderData(data);
        sessionStorage.setItem('orderData', JSON.stringify(data)); // Save to sessionStorage
    };

    const clearOrderData = () => {
        setOrderData(null);
        sessionStorage.removeItem('orderData');
    };

    return (
        <OrderContext.Provider value={{ orderData, saveOrderData, clearOrderData }}>
            {children}
        </OrderContext.Provider>
    );
};

export default OrderContext;
