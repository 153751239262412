import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form, FormGroup, Label, Container } from 'reactstrap';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import jsPDF from 'jspdf';
import { QRCodeCanvas } from 'qrcode.react';
import ct from '../../../assets/images/ct.png';
import QRCode from 'qrcode';

const SettingsPage = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [remainingPercentage, setRemainingPercentage] = useState(100);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [promoCodes, setPromoCodes] = useState(['K591THUV',
        '0SAOE1O2',
        '6VIL3FI1',
        'IH9D59NP',
        '2DT95LNK',
        'J6TNBVV8',
        'Q7HY2HMF',
        'L52N4CR3',
        'Q2C4T7R0',
        '899TSFY8',
        'UO16DGTR',
        '8GT3WYTZ',
        'PU6C7CV0',
        '0RWCZPAG',
        '6JN40H5P',
        'GNQW6523',
        'E33BHKAP',
        '0XU02144',
        'T38V36EB',
        'YZCIQWM3',
        'HKHB81IR',
        'EQ6ZVWDZ',
        'G2ANILCD',
        '6FOYGD9B',
        'YNPS9SLF',
        'TVA6TR9C',
        'JKN89CJG',
        'CWLUXAKY',
        'CK9XK5V8',
        '713POLP6',
        'O60TAT3M',
        'AP4NU0K3',
        '4AC0SNQY',
        'F0DQJU3B',
        'GVI3HLK6',
        'BP00EUZZ',
        'D8OB8E7N',
        'VL9K0N25',
        'PO9LXHUE',
        'HDVCCVCQ',
        'TKSEOQH3',
        '3NV4VMCG',
        'K6T0U0FK',
        'BQJOJW23',
        'LAGU7BPO',
        'FTTIFKL0',
        'QMLXMJYD',
        '9C06WYVQ',
        'D30PDG06',
        'WLNZ91MJ',
        '0IJ38XRP',
        '1QYKBFLZ',
        'D3CZAYF5',
        'O0GAMBLC',
        '45DTDYX6',
        '5JKYH6QP',
        '4JXMOYTV',
        'B1CWJHLX',
        'DLAJB2T3',
        '9V38TL6M',
        'P89YPSU8',
        'TAHKSIL8',
        'T73SOARG',
        '64NAIVS1',
        'URIVUBDU',
        'ME0HMJS8',
        'PVCRJ4H4',
        'M3B0A1JZ',
        'CC1J5YP1',
        '1V4NTUAO',
        'K8CSOHMR',
        'VUP80R02',
        'BQXZ98US',
        'IIJ2XR4Q',
        'R7Z4FQZC',
        'HAZ45H0E',
        '1HOF78YB',
        '8BV7D6OW',
        'WVQSNY6B',
        'YFRZV5A1',
        'P5DIIBDU',
        'KWILXP4F',
        '8GNL5ME1',
        'K53XB81Y',
        'A7JAGX65',
        'DMHYL3TK',
        '8UPQE52M',
        'DX38Y5HH',
        '1Y02A5NJ',
        'VEOZ9E94',
        '4UDFAL0W',
        '9JBIV604',
        'IEZY9PCT',
        'NYSG6AJD',
        'C96MYG0M',
        'M1IMTYSY',
        'N8Q3JFYI',
        '79873GUY',
        '257IHC01',
        'ODNHQ3VW',
        ]);
 
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await http.get(API_ENDPOINTS.PROJECT_CATEGORIES);
            setCategories(response.data.categories);
            calculateRemainingPercentage(response.data.categories);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setError('Failed to fetch categories.');
            setLoading(false);
        }
    };

    const calculateRemainingPercentage = (categories) => {
        const totalPercentage = categories.reduce((sum, category) => sum + category.percentage, 0);
        setRemainingPercentage(100 - totalPercentage);
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const handleCategoryInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedCategory({ ...selectedCategory, [name]: value });
    };

    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        toggleModal();
    };

    const handleSaveCategory = async () => {
        const totalPercentage = categories.reduce((sum, cat) => cat._id === selectedCategory._id ? sum + Number(selectedCategory.percentage) : sum + cat.percentage, 0);
        if (totalPercentage > 100) {
            setError('Total percentage cannot exceed 100');
            return;
        }
        try {
            await http.put(`${API_ENDPOINTS.PROJECT_CATEGORIES}/${selectedCategory._id}`, selectedCategory);
            fetchCategories();
            setSelectedCategory(null);
            toggleModal();
        } catch (error) {
            console.error('Error updating category:', error);
            setError('Failed to update category.');
        }
    };

    const generateCertificatesInOneFile = async () => {
        const doc = new jsPDF();
        let cert = 391;
    
        for (let i = 0; i < promoCodes.length; i++) {
            const code = promoCodes[i];
    
            // Load the background image (optional)
            const img = new Image();
            img.src = ct;
    
            let imgWidth, imgHeight;
    
            // Wait for the image to load
            await new Promise(resolve => {
                img.onload = () => {
                    // Set image dimensions
                    imgWidth = img.width;
                    imgHeight = img.height;
    
                    // Set the PDF page size to match the image dimensions
                    doc.internal.pageSize.width = imgWidth * 0.264583; // Convert px to mm
                    doc.internal.pageSize.height = imgHeight * 0.264583;
    
                    // Add the image, centering it on the page
                    doc.addImage(img, 'PNG', 0, 0, imgWidth * 0.264583, imgHeight * 0.264583);
    
                    resolve();
                };
            });
    
            // Generate the QR code and add it to the PDF
            const qrDataUrl = await QRCode.toDataURL(`https://c-mass.co/promo-signup?code=${code}`, { width: 100 });
            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(15);
            doc.setTextColor('#222222');
    
            // Position text and QR code to fit within the image dimensions
            doc.text(`IN-5-314023${cert++}-1-1-0-6912`, imgWidth * 0.5 * 0.126583, imgHeight * 0.9 * 0.250583, { align: "center" });
            doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.264583 - 12.5, imgHeight * 0.85 * 0.240783, 35, 35);
    
            // Add a new page if this is not the last code
            if (i < promoCodes.length - 1) {
                doc.addPage();
            }
        }
    
        // Save the PDF with all promo codes in one file
        doc.save('Certificates.pdf');
    };
    
    // Function to generate certificates for all promo codes
    const generateAllCertificates = () => {
        generateCertificatesInOneFile();
    };
    

    return (
        <Container className="dashboard">
            <h2>Settings</h2>
            <h3>Project Categories</h3>

            {error && <p className="text-danger">{error}</p>}

            {loading ? <Spinner /> : (
                <Table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Percentage</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map(category => (
                            <tr key={category._id}>
                                <td>{category.name}</td>
                                <td>{category.description}</td>
                                <td>{category.percentage}%</td>
                                <td>
                                    <Button
                                        color="primary"
                                        onClick={() => handleEditCategory(category)}
                                        style={{ borderRadius: '30px', padding: '6px 14px' }}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Button color="success" onClick={generateAllCertificates}>
                Generate Certificates for All Promo Codes
            </Button>

            {selectedCategory && (
                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Edit Category</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="name">Category Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={selectedCategory.name}
                                    onChange={handleCategoryInputChange}
                                    className="form-control mb-2"
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={selectedCategory.description}
                                    onChange={handleCategoryInputChange}
                                    className="form-control mb-2"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="percentage">Percentage</Label>
                                <Input
                                    type="number"
                                    name="percentage"
                                    id="percentage"
                                    value={selectedCategory.percentage}
                                    onChange={handleCategoryInputChange}
                                    className="form-control mb-2"
                                    min="0"
                                    max={remainingPercentage + selectedCategory.percentage}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSaveCategory} style={{ borderRadius: '30px', padding: '6px 14px' }}>
                            Save Changes
                        </Button>
                        <Button color="secondary" onClick={toggleModal} style={{ borderRadius: '30px', padding: '6px 14px' }}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </Container>
    );
};

export default SettingsPage;
