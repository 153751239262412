import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import classnames from 'classnames';
import '../../assets/css/PortfolioDetails.css'; // Assuming you will have some custom CSS for styling
import { useParams } from "react-router-dom";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ModeContext } from '../../contexts/mode/ModeContext';

const PortfolioDetails = ({ match }) => {
  const [portfolio, setPortfolio] = useState(null);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(true);
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  const { isSandboxMode } = useContext(ModeContext);

  useEffect(() => {
    // Fetch portfolio and projects
    const fetchPortfolioDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.PORTFOLIO}/${portfolioId}?isSandbox=${isSandboxMode}`);
        const { portfolio, projects } = response.data;
        setPortfolio(portfolio);
        setProjects(projects);
        // Get unique categories from the projects
        const uniqueCategories = [...new Set(projects.map(proj => proj.projectCategory?.name))];
        setCategories(uniqueCategories);
        // Set default active tab to the first category
        setActiveTab(uniqueCategories[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching portfolio details:", error);
      }
    };

    fetchPortfolioDetails();
  }, [portfolioId, isSandboxMode]);

  const toggleTab = (category) => {
    if (activeTab !== category) setActiveTab(category);
  };

  const handleProjectClick = (projectId) => {
    navigate(`/profile/project/${projectId}`);
  };

  const getCategoryClass = (category) => {
    switch (category?.toLowerCase()) {
      case 'emissions reductions':
        return 'emissions';
      case 'conservation':
        return 'conservation';
      case 'nature-based removal':
        return 'nature-based';
      case 'long-lived removal':
        return 'long-lived';
      default:
        return '';
    }
  };

  return (
    <Container>
      {/* Portfolio Overview */}

      {loading ? <Spinner /> : <>
        <Row className="portfolio-overview mb-4">
          <Col md="6">
            <h1>{portfolio?.name}</h1>
            <p>{portfolio.description}</p>
          </Col>
          <Col md="6">
            
          </Col>
        </Row>


      {/* Project Categories */}
      <Nav tabs className="category-tabs mb-4">
            {categories.map((category, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames(getCategoryClass(category), { active: activeTab === category })}
                  onClick={() => toggleTab(category)}
                >
                  {category}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

        {/* Project List under each category */}
        <TabContent activeTab={activeTab}>
          {categories.map((category, index) => (
            <TabPane tabId={category} key={index}>
              <Row>
                {projects
                  .filter((project) => project.projectCategory?.name === category)
                  .map((project, idx) => (
                    <Col md="4" key={idx}>
                      <Card className="mb-4 project-card" style={{borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none'}} onClick={() => handleProjectClick(project._id)}>
                        <img
                          top
                          src={project.image || 'default-project-image.jpg'}
                          alt={project?.name}
                          className="project-image"
                        />
                        <CardBody>
                          <CardTitle tag="h5">{project?.name}</CardTitle>
                          <CardText>{project.description}</CardText>
                          <p><strong>Developer:</strong> {project.developer}</p>
                          <p><strong>Vintages:</strong> {project.vintages?.join(', ')}</p>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </>}
    </Container>
  );
};

export default PortfolioDetails;