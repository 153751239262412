// src/pages/ChoosePortfolio.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import '../../assets/css/choosePortfolio.css';
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ModeContext } from '../../contexts/mode/ModeContext';

const ChoosePortfolio = () => {
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { isSandboxMode } = useContext(ModeContext);

    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const portfolioResponse = await http.get(`${API_ENDPOINTS.PORTFOLIO}?isSandbox=${isSandboxMode}`);
                setPortfolios(portfolioResponse.data.portfolios);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching portfolios:", error);
                setLoading(false);
            }
        };

        fetchPortfolios();
    }, [isSandboxMode]);

    const handlePortfolioClick = (portfolioId) => {
        navigate(`/profile/portfolio/${portfolioId}`);
      };

return (
        <Container fluid className="choose-portfolio-container main-content expanded">
            <h1>Choose portfolio to explore</h1>
            {loading ? <Spinner /> : <Row>
                {portfolios?.map((portfolio) => (
                    <Col xs="12" sm="6" md="6" key={portfolio._id}>
                        <Card className="portfolio-card" style={{borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none', minWidth: '50px'}} onClick={() => handlePortfolioClick(portfolio._id)}>
                            <CardBody className='card-body' style={{padding: '0'}}>
                                <div className="portfolio-image" style={{ backgroundImage: `url(${portfolio.img})`, borderRadius: '25px 25px 0px 0px' }}></div>
                                <CardTitle tag="h3">{portfolio.name}</CardTitle>
                                <CardText>{portfolio.description}</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>}
        </Container>
    );
};

export default ChoosePortfolio;
