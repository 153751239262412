import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Button, Progress } from 'reactstrap';
import http from "../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../contexts/mode/ModeContext';
import Spinner from "react-bootstrap/Spinner";
import '../assets/css/ProjectsSupportedCard.css';
import { useNavigate, useOutletContext } from 'react-router-dom';

const ProjectsSupportedCard = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { company } = useOutletContext();
  const { isSandboxMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.SUPPORTED_PROJECTS}/${company._id}?isSandbox=${isSandboxMode}`); 
        setProjects(response.data.projects);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setProjects([]);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [isSandboxMode]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Card className="projects-supported-card" style={{ borderRadius: '15px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', border: 'none' }}>
      {projects.length == 0 ? <CardBody>
        <h5 className="projects-supported-title">Projects Supported</h5>
        <p>No data</p>
        
      </CardBody> : <CardBody>
        <h5 className="projects-supported-title">Projects Supported</h5>
        {/* Progress bar showing the combined contributions */}
        <Progress multi style={{ height: '8px', borderRadius: '12px' }}>
          {projects.map((project, index) => (
            <Progress bar key={index} value={project.percentage} style={{ backgroundColor: project.color }} />
          ))}
        </Progress>

        <ul className="projects-list mt-3">
          {projects.map((project, index) => (
            <li key={index} className="project-item">
              <span className="project-dot" style={{ backgroundColor: project.color }}></span>
              <span className="project-name">{project.name}</span>
              <span className="project-percentage">{project.percentage}%</span>
            </li>
          ))}
        </ul>
      </CardBody>}
    </Card>
  );
};

export default ProjectsSupportedCard;
