import React from 'react';
import { Col } from "reactstrap";

const ProductShowcase = ({ title, description, imgSrc, btnText, btnLink, num }) => {
    return (
        <Col lg={12} md={12} sm={12} className="mb-4"> {/* Full row on large screens */}
            <div style={styles.productShowcase}>
                {num % 2 !== 0 ? (
                    <div style={{ ...styles.productImage, marginRight: '20px' }}> {/* Add margin-right */}
                        <img src={imgSrc} alt={`${title}`} style={styles.image} />
                    </div>
                ) : null}
                <div style={styles.productDetails}>
                    <h2 style={styles.title}>{title}</h2>
                    <p style={styles.description}>{description}</p>
                </div>
                {num % 2 === 0 ? (
                    <div style={{ ...styles.productImage, marginLeft: '20px' }}> {/* Add margin-left for right-side image */}
                        <img src={imgSrc} alt={`${title}`} style={styles.image} />
                    </div>
                ) : null}
            </div>
        </Col>
    );
};

const styles = {
    productShowcase: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items side by side
        alignItems: 'center',
        marginBottom: '40px',
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: '#fdfdfd',
    },
    productDetails: {
        maxWidth: '50%', // Make the text take 50% of the width
    },
    title: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    description: {
        fontSize: '1rem',
        color: '#6c757d',
        marginBottom: '20px',
    },
    productImage: {
        maxWidth: '40%', // Limit the image width to prevent it from overtaking the text
    },
    image: {
        width: '100%',
        borderRadius: '12px',
    },
    // Media query for smaller screens
    '@media (max-width: 768px)': {
        productShowcase: {
            flexDirection: 'column',
        },
        productDetails: {
            maxWidth: '100%',
            textAlign: 'center',
        },
        productImage: {
            maxWidth: '100%',
            textAlign: 'center',
        },
        title: {
            fontSize: '1.5rem',
        },
        description: {
            fontSize: '1rem',
        },
    },
};

export default ProductShowcase;
