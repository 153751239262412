import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const ModeContext = createContext();

// Create the provider component
export const ModeProvider = ({ children }) => {
  const [isSandboxMode, setIsSandboxMode] = useState(false);

  // On initial load, check localStorage
  useEffect(() => {
    const savedMode = localStorage.getItem('sandboxMode');
    if (savedMode) {
      setIsSandboxMode(savedMode === 'true');
    }
  }, []);

  // Update localStorage whenever the mode changes
  useEffect(() => {
    localStorage.setItem('sandboxMode', isSandboxMode);
  }, [isSandboxMode]);

  return (
    <ModeContext.Provider value={{ isSandboxMode, setIsSandboxMode }}>
      {children}
    </ModeContext.Provider>
  );
};
