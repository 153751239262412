import React from 'react';

const ProjectTypesChart = ({ orders }) => {
    // Step 1: Calculate the total credits purchased for each project type
    const projectTypeMap = {};

    orders?.forEach(order => {
        order.projectRecords?.forEach(record => {
            const projectType = record.projectId?.type || 'Unknown Type';
            const credits = Math.abs(record.delta);

            if (projectTypeMap[projectType]) {
                projectTypeMap[projectType] += credits;
            } else {
                projectTypeMap[projectType] = credits;
            }
        });
    });

    // Step 2: Calculate the total credits purchased
    const totalCredits = Object.values(projectTypeMap).reduce((acc, curr) => acc + curr, 0);

    // Step 3: Calculate the percentage for each project type
    const projectTypeData = Object.keys(projectTypeMap).map(projectType => ({
        projectType,
        percentage: ((projectTypeMap[projectType] / totalCredits) * 100).toFixed(1)
    }));

    const colors = ['#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B']; // Different shades for variety

    return (
        <div className="project-types">
            <h5 className="stat-title">Project Types</h5>
            <div className="stat-chart">
                {projectTypeData.map((data, index) => (
                    <div key={index} className="type-bar" style={{ marginBottom: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div 
                                className="color-dot" 
                                style={{ 
                                    width: '12px', 
                                    height: '12px', 
                                    borderRadius: '50%', 
                                    backgroundColor: colors[index % colors.length], 
                                    marginRight: '8px' 
                                }} 
                            />
                            <span className="type-name">{data.projectType}</span>
                            <div className="percentage" style={{ marginLeft: 'auto', marginRight: '8px' }}>{data.percentage}%</div>
                        </div>
                        <div 
                            className="progress-bar" 
                            style={{ 
                                width: `${data.percentage}%`, 
                                backgroundColor: colors[index % colors.length], 
                                height: '8px', 
                                borderRadius: '4px',
                                marginTop: '4px' 
                            }} 
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjectTypesChart;
